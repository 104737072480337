import { ChatRequest } from './../features/chat/model';

import {AudioTranscribeRequest } from "../features/chat/model";
import {
  getTanderChatApiUrl,
  getTanderAudioTranscribeApiUrl
} from "./environment"


export async function sendChatMessage(chatRequest: ChatRequest): Promise<string> {
  const response = await fetch(getTanderChatApiUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(chatRequest),
  });

  const data = await response.json();
  return data['ai_text_response'];
}

export async function sendAudioTrancribe(
  transcribeRequest: AudioTranscribeRequest): Promise<string> {
  const formData = new FormData();
  formData.append('file', transcribeRequest.audioFile);
  formData.append('json', JSON.stringify({"options": transcribeRequest.options}));

  const response = await fetch(getTanderAudioTranscribeApiUrl(), {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    body: formData,
  });

  const data = await response.json();
  return data['user_transcribed_message'];
}

// export async function sendAudioChatMessage(
//   audioFile: File, transcibeRequest: AudioTranscribeRequest): Promise<string> {
//   const formData = new FormData();
//   formData.append('file', audioFile);
//   formData.append('json', JSON.stringify({ "previous_messages": previousChatLogs, "options": options }));

//   const response = await fetch(getTanderAudioChatApiUrl(), {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       'Access-Control-Allow-Origin': '*',
//     },
//     body: formData,
//   });

//   const data = await response.json();
//   console.log(data);
//   return data['ai_text_response'];
// }


