import React, { useState, useEffect } from "react";
import "./Slider.css";

type SliderProps = {
    onSlideChange: Function;
};

export const Slider = (props: SliderProps) => {
    const [value, setValue] = useState("easy");

    useEffect(() => {
        setValue("2");
        props.onSlideChange("B1"); 
      }, []);

    const handleChange = (event: any) => {
        const languageLevel = mapIndexToLevel(event.target.value);
        props.onSlideChange(languageLevel);
        setValue(event.target.value);
    };

    const mapIndexToLevel = (index: string) => {
        switch (index) {
            case "0":
                return "A1";
            case "1":
                return "A2";
            case "2":
                return "B1";
            case "3":
                return "B2";
            case "4":
                return "C1";
            case "5":
                return "C2";
            default:
                return "INVALID_LEVEL"
        }
    };

    return (
        <div className="slide-container">
            <input
                type="range"
                min="0"
                max="5"
                step="1"
                value={value}
                onChange={handleChange}
            />
            <div className="labels">
                <span className={value === "0" ? "active" : ""}>A1</span>
                <span className={value === "1" ? "active" : ""}>A2</span>
                <span className={value === "2" ? "active" : ""}>B1</span>
                <span className={value === "3" ? "active" : ""}>B2</span>
                <span className={value === "4" ? "active" : ""}>C1</span>
                <span className={value === "5" ? "active" : ""}>C2</span>
            </div>
        </div>
    );
};
