import { ChatWrapper } from './features/chat/Chat';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ChatWrapper />
      </header>
    </div>
  );
}

export default App;
