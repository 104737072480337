import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { ChatMessage, ChatOptions, ChatRequest, AudioTranscribeRequest } from './model';
import { sendChatMessage, sendAudioTrancribe } from '../../api/sendPromptMessage';
import { toast } from "react-toastify";

export interface ChatState {
    status: 'idle' | 'loading' | 'failed' | 'audio-uploading';
    messageLogs: ChatMessage[];
    options: ChatOptions
}

const initialState: ChatState = {
    status: 'idle',
    messageLogs: [],
    options: {
        level: "A1",
        language: "German",
    },
};

const sendChatMessageAsync = createAsyncThunk(
    'chat/sendMessage',
    async (request: ChatRequest) => {
        return await sendChatMessage(request);
    }
);

const sendAudioTranscribeAsync = createAsyncThunk(
    'chat/sendAudioTranscribe',
    async (request: AudioTranscribeRequest) => {
        return await sendAudioTrancribe(request);
    }
);

const loadAudioTranscribeAsync = createAsyncThunk(
    'chat/loadAudioTranscribe',
    async (request: AudioTranscribeRequest) => {
        return await sendAudioTrancribe(request);
    }
);


export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        addChatMessage: (state, action: PayloadAction<ChatMessage>) => {
            state.messageLogs = [...state.messageLogs, action.payload];
        },
        setChatStatusReducer: (state, action: PayloadAction<string>) => {
            state.status = action.payload as 'idle' | 'loading' | 'failed' | 'audio-uploading';
        },        
        setLanguageLevel: (state, action: PayloadAction<string>) => {
            state.options = {
                ...state.options,
                level: action.payload
            };
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            state.options = {
                ...state.options,
                language: action.payload
            };
            state.messageLogs = []
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            // Send chat request cases
            .addCase(sendChatMessageAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(sendChatMessageAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                const newLog: ChatMessage = {
                    role: 'assistant',
                    message: action.payload
                };
                state.messageLogs = [...state.messageLogs, newLog];
            })
            .addCase(sendChatMessageAsync.rejected, (state) => {
                state.status = 'failed';
            })
            // Transcribe cases
            .addCase(sendAudioTranscribeAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(sendAudioTranscribeAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                if(!action.payload) {
                    toast.warn("Recording has no transcription");
                    return;
                }
                const newLog: ChatMessage = {
                    role: 'user',
                    message: action.payload
                };
                    state.messageLogs = [...state.messageLogs, newLog];
            })
            .addCase(sendAudioTranscribeAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const {
    addChatMessage,
    setLanguage,
    setLanguageLevel,
    setChatStatusReducer
} = chatSlice.actions;

export const selectChatLogs = (state: RootState) => state.chat.messageLogs;
export const selectChatStatus = (state: RootState) => state.chat.status;
export const selectChatOptions = (state: RootState) => state.chat.options;
export const selectIsAssistantTurn = (state: RootState) => {
    return state.chat.messageLogs[-1].role === "user"
        && state.chat.status === "idle"
}

export const setUserMessage =
    (message: string): AppThunk =>
        (dispatch, getState) => {
            const userMessage: ChatMessage = {
                role: "user",
                message: message,
            };
            dispatch(addChatMessage(userMessage));
        };

export const setChatStatus =
    (status: string): AppThunk =>
        (dispatch, getState) => {
            dispatch(setChatStatusReducer(status));
        };

export const fetchTanderChatResponse =
    (): AppThunk =>
        (dispatch, getState) => {
            const currentLogs = selectChatLogs(getState());
            const currentChatOptions = selectChatOptions(getState());
            const request: ChatRequest = {
                messages: currentLogs,
                options: currentChatOptions!,
            };
            dispatch(sendChatMessageAsync(request));
        };

export const DEPRECATED_sendUserMessage =
    (message: string): AppThunk =>
        (dispatch, getState) => {
            const userMessage: ChatMessage = {
                role: "user",
                message: message,
            };
            dispatch(addChatMessage(userMessage));
            // Optimize by using internal state.
            const currentLogs = selectChatLogs(getState());
            const currentChatOptions = selectChatOptions(getState());
            const request: ChatRequest = {
                messages: currentLogs,
                options: currentChatOptions!,
            };
            dispatch(sendChatMessageAsync(request));
        };

export const sendAudioTranscribe =
    (audioFile: File): AppThunk =>
        (dispatch, getState) => {
            // dispatch seding audiofile
            // Optimize by using internal state.
            const currentChatOptions = selectChatOptions(getState());
            const request: AudioTranscribeRequest = {
                audioFile: audioFile,
                options: currentChatOptions!,
            };
            dispatch(sendAudioTranscribeAsync(request));
        };

export default chatSlice.reducer;
