import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faStop } from "@fortawesome/free-solid-svg-icons";

import "./AudioRecorder.css";

interface AudioRecorderProps {
  onRecordStart: Function;
  onRecordStop: Function;
}

export const AudioRecorder = (props: AudioRecorderProps) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioChunksRef = useRef<Blob[]>([]);

  const handleRecordStart = () => {
    console.log('Recording Started...');
    audioChunksRef.current = [];
    props.onRecordStart();
    setIsRecording(true);
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const mediaRecorder = new MediaRecorder(stream);
      // const chunks: Blob[] = [];

      mediaRecorder.addEventListener("dataavailable", (event: BlobEvent) => {
        console.log('Recording Chunk Added...');
        audioChunksRef.current.push(event.data);
      });

      mediaRecorder.addEventListener("stop", () => {
        const audioBlob = new Blob(audioChunksRef.current);
        const file = new File([audioBlob], "recording.wav", { type: "audio/wav" });
        console.log(file);
        // setAudioFile(audioFile);
        setIsRecording(false);
        props.onRecordStop(file);
        const audioUrl = URL.createObjectURL(file);
        audioRef.current?.setAttribute("src", audioUrl);
        console.log('Recording Stop.');
        // console.log('Recording Stop 1');
        // const blob = new Blob(chunks);
        // const file = new File([blob], "recording.mp3", { type: "audio/mp3" });
        // setAudioFile(audioFile);
        // props.onRecordStop(file);
        // const audioUrl = URL.createObjectURL(file);
        // audioRef.current?.setAttribute("src", audioUrl);
        // console.log('Recording Stop 2');
        // setIsRecording(false);
      });

      mediaRecorder.start();
      setTimeout(() => mediaRecorder.stop(), 15000); // Stop recording after 15 seconds
    });
  };

  const handleRecordStop = () => {
    // const audioBlob = new Blob(audioChunksRef.current);
    // const file = new File([audioBlob], "recording.wav", { type: "audio/wav" });
    // console.log(file);
    // setAudioFile(audioFile);
    setIsRecording(false);
    // props.onRecordStop(file);
    // const audioUrl = URL.createObjectURL(file);
    // audioRef.current?.setAttribute("src", audioUrl);
    console.log('Recording Stop.');
  };

  // const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     setAudioFile(file);
  //     const audioUrl = URL.createObjectURL(file);
  //     audioRef.current?.setAttribute("src", audioUrl);
  //   }
  // };


  const handleClick = () => {
    if (isRecording) {
      handleRecordStop();
    } else {
      handleRecordStart();
    }
  };

  return (
    <div>
      <button
        className={`audio-recorder-button ${isRecording ? "recording" : ""}`}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={isRecording ? faStop : faMicrophone} />
      </button>
      {/* <audio ref={audioRef} controls className="audio-recorder-audio" /> */}

    </div>
    // <div className="audio-recorder">
    //   <button
    //     className={`audio-recorder-button ${isRecording ? "recording" : ""}`}
    //     onClick={handleClick}
    //   />
    //   {/* <button
    //     onMouseDown={handleRecordStart}
    //     onMouseUp={handleRecordStop}
    //     onTouchStart={handleRecordStart}
    //     onTouchEnd={handleRecordStop}
    //     // onTouchCancel={handleRecordStop}
    //     className={`audio-recorder-button ${isRecording ? "recording" : ""}`}
    //   >
    //     {isRecording ? (
    //       <p>Recording...</p>
    //     ) : (
    //       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
    //         <path d="M12 15c-1.66 0-3-1.34-3-3V6c0-1.66 1.34-3 3-3s3 1.34 3 3v6c0 1.66-1.34 3-3 3zM15 0H9C7.34 0 6 1.34 6 3v9c0 1.66 1.34 3 3 3h6c1.66 0 3-1.34 3-3V3c0-1.66-1.34-3-3-3z" />
    //       </svg>
    //     )}
    //   </button>
    //   <audio ref={audioRef} controls className="audio-recorder-audio" />
    //   <label htmlFor="audio-upload" className="audio-recorder-upload-label">
    //     Upload audio file:
    //     <input id="audio-upload" type="file" accept="audio/*" onChange={handleFileUpload} className="audio-recorder-upload-input" />
    //   </label> */}
    //   {/* {audioFile && <p>Uploaded file: {audioFile.name}</p>} */}
    // </div>
  );
};