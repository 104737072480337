import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
    selectChatLogs,
    selectChatStatus,
    setUserMessage,
    setLanguage,
    setChatStatus,
    setLanguageLevel,
    fetchTanderChatResponse,
    sendAudioTranscribe,
} from './chatSlice';
import { Slider } from './Slider';
import { LanguageSelector } from './LanguageSelector';
import { useEffect, useState, useRef } from 'react';
import { ChatMessage } from './model';
import { AudioRecorder } from './AudioRecorder';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type ChatProps = {
    chatLogs: ChatMessage[];
    chatStatus: 'idle' | 'loading' | 'failed' | 'audio-uploading';
    onSendClick: Function;
};

export function Chat(props: ChatProps) {
    const [chatMessage, setChatMessage] = useState("");
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();

    function isLoading() {
        return props.chatStatus === 'loading' || props.chatStatus === 'audio-uploading';
    }

    function isAudioUploading() {
        return props.chatStatus === 'audio-uploading';
    }

    function isChatDisabled() {
        return isLoading() || chatMessage == "";
    }

    function handleSendEvent() {
        props.onSendClick(chatMessage);
        setChatMessage("");
    }

    function getLastMessageRole() {
        if (props.chatLogs.length > 0) {
            const last_role = props.chatLogs[props.chatLogs.length - 1].role;
            return last_role;
        }
        return "INVALID"
    }

    useEffect(() => {
        if (messagesEndRef) {
            messagesEndRef.current!.scrollIntoView({ behavior: 'smooth' });
        }
        if (props.chatLogs.length > 0) {
            if (props.chatLogs[props.chatLogs.length - 1].role === "user" && props.chatStatus === "idle") {
                dispatch(fetchTanderChatResponse());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chatLogs, props.chatStatus]);

    return (
        <div>
            {/* <div className='chat-greeting'>Teez - The Language Learning Companion</div> */}
            <div className="chat-log-container-wrapper"></div>
            <div className="chat-log-container">
                <div className="chat-logs">
                    {props.chatLogs.map((chat: ChatMessage, index: number) => (
                        <div key={index} className={
                            `chat-message ${chat.role === 'user' ? 'user-message' : 'bot-message'}`}>
                            <p>{chat.message}</p>
                        </div>
                    ))}
                    {isLoading() && (
                        <div key="typing" className={
                            getLastMessageRole() === 'user' ?
                                'chat-message bot-message' :
                                `chat-message user-message`}>
                            <div className="loading-dots">
                                <div className="loading-dot-1"> . </div>
                                <div className="loading-dot-2">.</div>
                                <div className="loading-dot-3"> . </div>
                            </div>
                        </div>)}
                    <div ref={messagesEndRef} />
                </div>
                <div className="input-container">
                    <input
                        disabled={isLoading()}
                        type="text"
                        value={chatMessage}
                        onChange={(e) => setChatMessage(e.target.value)}
                        onKeyDown={(e) => { if (e.keyCode === 13) { return handleSendEvent(); } }}
                    />
                    <button type='submit' disabled={isChatDisabled()}
                        className={`send-button ${isChatDisabled() ? 'disabled' : ''} `}
                        onClick={() => {
                            return handleSendEvent();
                        }}> Send </button>
                </div>
                <div className="RecordContainer">
                    <AudioRecorder
                        // chatStatus={props.chatStatus}
                        onRecordStart={() => {
                            dispatch(setChatStatus('audio-uploading'));
                        }}
                        onRecordStop={(audioFile: File) => {
                            dispatch(sendAudioTranscribe(audioFile));
                        }} />
                </div>
                <div className='options-container'>
                    <LanguageSelector onLanguageChange={(language: string) => {
                        dispatch(setLanguage(language))
                    }} />
                    <Slider onSlideChange={(languageLevel: string) => {
                        dispatch(setLanguageLevel(languageLevel))
                    }} />
                </div>
            </div>
        </div>
    );
}

export function ChatWrapper() {
    const chatLogs = useAppSelector(selectChatLogs);
    const chatStatus = useAppSelector(selectChatStatus);
    const dispatch = useAppDispatch();

    useEffect(() => {
    }, []);

    return (
        <div>
            <Chat
                chatLogs={chatLogs}
                chatStatus={chatStatus}
                onSendClick={(message: string) => { dispatch(setUserMessage(message)) }}
            />
        </div>
    );
}