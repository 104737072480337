import React, { useState, useEffect } from "react";
import "./LanguageSelector.css";
// import germanFlag from "./german-flag.png";
// import spanishFlag from "./spanish-flag.png";
// import frenchFlag from "./french-flag.png";
// import portugueseFlag from "./portuguese-flag.png";
// import englishFlag from "./english-flag.png";
// import ItalianFlag from "./italy-flag.png";

const languages = [
  { label: "German", value: "German"},
  { label: "Spanish", value: "Spanish"},
  { label: "French", value: "French"},
  { label: "Portuguese", value: "Portuguese"},
  { label: "English", value: "English"},
  { label: "Italian", value: "Italian"},
];

type LanguageSelectorProps = {
    onLanguageChange: Function;
};

export const LanguageSelector = (props: LanguageSelectorProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);

  useEffect(() => {
    props.onLanguageChange("German"); 
  }, []);

  const handleSelect = (event: any) => {
    const selectedValue = event.target.value;
    const selectedOption = languages.find((option) => option.value === selectedValue);
    setSelectedLanguage(selectedOption!);
    props.onLanguageChange(selectedOption?.value)
  };

  return (
    <div className="dropdown-container">
      <select value={selectedLanguage.value} onChange={handleSelect}>
        {languages.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {/* <img src={selectedLanguage.flag} alt={`${selectedLanguage.label} flag`} /> */}
    </div>
  );
};