
export function isDebug() {
    const env = process.env.REACT_APP_DEBUG;
    return env && env === "true" ;
}
export function getTanderApiUrl() {
    const url = process.env.REACT_APP_TANDER_API_URL;
    return url;
}

export function getTanderChatApiUrl() {
    return `${getTanderApiUrl()}/chat`;
}

export function getTanderAudioChatApiUrl() {
    return `${getTanderApiUrl()}/audiochat`;
}

export function getTanderAudioTranscribeApiUrl() {
    return `${getTanderApiUrl()}/audiotranscribe`;
}